@use '../abstracts/' as *;

.dice-roll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    @include at-least(desktop) {
        align-items: flex-start;
    }
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    max-width: 600px;
    position: relative;
    width: 100%;
}

.dice-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.button--dice-roller {
    display: flex;
    align-items: center;
    justify-content: center;
}
