@use '../abstracts/' as *;

.card {
  background-color: var(--color-bg-home-card);
  border-radius: 10px;
}

.contribution__card,
.project__card {
  max-width: 640px;
  padding: 25px;
  width: 100%;
}

.contribution__contributee {
  opacity: $low-emphasis;
}

.contribution__description {
  margin: 15px 0;
}

.home-card {
  padding: 20px;
  width: min(100%, 300px);

  @include at-least(tablet-portrait) {
    width: min(100%, 500px);
  }

  @include at-least(desktop) {
    width: min(100%, 350px);
  }

  & + .home-card {
    margin-top: 50px;

    @include at-least(desktop) {
      margin-top: 0;
    }
  }
}

.home-card__image {
  width: 100%;

  @include at-least(desktop) {
    width: 75%;
  }
}

.project__image-container {
  min-height: 150px;

  @include at-least(tablet-portrait) {
    min-height: 250px;
  }
}

.project__image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: auto;
  width: 100%;
}

.project__title {
  @include at-least(tablet-portrait) {
    margin-top: 25px;
  }
}
