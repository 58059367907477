@use '../abstracts' as *;

.about__main,
.contribution__description,
.project__description {
    font-size: 1.25rem;
}

.about__pronouns {
    font-style: italic;
    text-align: center;
}

.about__skill-icon {
    font-size: 3rem;
}

.about__title,
.home-info__intro,
.home-card__title,
.intro__description,
.page__header {
    text-align: center;
}

.about__title {
    @include at-least(tablet-portrait) {
        text-align: left;
    }
}

body {
    color: var(--color-text-primary);
    font-family: $base-fonts;
}

.button--contained,
.button--outline,
.button--text,
.list-item--bullet,
.home-card__text {
    font-size: 1.125rem;
}

.button--contained,
.button--outline,
.button--text {
    color: var(--color-text-primary);
    text-decoration: none;
}

.button--contained {
    &:focus,
    &:hover {
        color: var(--color-text-btn-contained);
    }
}

.contribution__description,
.project__description {
    opacity: 0.8;
}

.dice__result {
    font-size: 3rem;
    color: black;

    @include at-least(tablet-portrait) {
        font-size: 5rem;
    }
}

.footer-nav-link,
.header-nav-link {
    color: var(--color-text-primary);
}

.footer-nav-link {
    &:focus,
    &:hover {
        text-decoration: none;
    }
}

.footer__pun {
    font-size: 1.125rem;
}

h1 {
    @include at-least(tablet-portrait) {
        font-size: 3.5rem;
    }
}

h2 {
    @include at-least(tablet-portrait) {
        font-size: 1.75rem;
    }
}

.header-nav-link {
    font-size: 1.5rem;
    text-decoration: none;

    @include at-least(tablet-portrait) {
        font-size: 1.125rem;
    }

    @include at-least(tablet-landscape) {
        font-size: 1.5rem;
    }
}

.home-card__text,
.home-info__intro,
.intro__description,
.page__header {
    @include at-least(desktop) {
        text-align: left;
    }
}

.home-card__text {
    @include at-least(desktop) {
        font-size: 1.25rem;
    }
}

.intro__description {
    font-size: 1.5rem;

    @include at-least(tablet-landscape) {
        font-size: 2rem;
    }
}

.link {
    color: var(--color-text-primary);

    &:focus,
    &:hover {
        text-decoration: none;
    }
}

.skip-link {
    color: var(--color-text-primary);
    text-decoration: none;
}
