.about__list,
.contribution__list {
    padding-left: 15px;
}

.list-item--bullet {
    + .list-item--bullet {
        margin-top: 10px;
    }
}

.skills__list {
    list-style-type: none;
    padding: 20px;
    margin: 0;
}
