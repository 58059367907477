@use '../abstracts/' as *;

.footer__nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px 25px;
}

.footer__secondary {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
