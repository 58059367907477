@use '../abstracts/' as *;

.home-card-container,
.home-card,
.project__card,
.project__link-container {
  display: flex;
}

.home-card-container,
.home-card,
.project__card {
  flex-direction: column;
}

.home-card-container {
  align-items: center;

  @include at-least(desktop) {
    align-items: stretch;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.home-card {
  @include at-least(tablet-portrait) {
    gap: 10px;
    flex-direction: row;
  }

  @include at-least(desktop) {
    flex-direction: column;
  }
}

.home-card__image {
  align-self: center;
}

.project__card {
  gap: 25px;
}

.project__title {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @include at-least(tablet-portrait) {
    align-items: start;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    justify-content: space-between;
  }
}

.project__link-container {
  justify-content: center;
  width: max-content;
}
