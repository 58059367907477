@use '../abstracts/' as *;
@use '../utilities/theme-utilities' as *;

:root {
  @include generate-theme-variables;
  height: 100%;
}

body {
  background-color: var(--color-bg-base);
  height: 100%;
  overflow-x: hidden;

  &.menu-open {
    overflow-y: scroll;
    position: fixed;
  }
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  background-color: var(--color-bg-header);
  width: 100%;
}

.header-container {
  max-width: 1500px;
  padding: 0.5rem 1rem;
  width: 100%;
}

main {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  padding: 2.5rem 1rem 5rem;
}

.page-container {
  max-width: 1400px;
  width: 100%;
}

.page__header {
  margin-bottom: 50px;
}

footer {
  background: var(--color-bg-footer);
  padding: 2rem 1rem;
}

.footer__pun {
  margin: 0;
  opacity: 0.7;
}

/**
  * Removes default focus styles for elements 
  * (MANUALLY ADD FOCUS STYLES)
  */
* {
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

%clickable {
  &:hover {
    cursor: pointer;
  }
}

/**
  * Add 'cursor: pointer' rule to all tags and aria-roles where
  * a pointer cursor would be applicable
  */
button,
label,
[role='button'],
[role='checkbox'],
[role='switch'],
[type='date'] {
  @extend %clickable;

  &:disabled {
    cursor: not-allowed;
    opacity: $disabled;
  }
}

input[aria-disabled='true'],
input:disabled {
  opacity: $disabled;

  &:hover {
    cursor: not-allowed;
  }
}
