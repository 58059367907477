@use '../abstracts' as *;

.button {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button--contained {
    @extend .button;
    background-color: var(--color-btn-contained);
    border: 2px solid transparent;
    padding: 0.75rem 1rem;

    &:hover,
    &:focus {
        background-color: var(--color-btn-contained-focus);
    }

    &:focus {
        border: 2px solid var(--color-border-btn-focus);
    }
}

.button--delete {
    @extend .button;
    background-color: transparent;
    border: 1px solid transparent;

    &:focus,
    &:hover {
        border: 1px solid var(--color-border-btn-delete);
    }
}

.button--dice-roller {
    background-color: transparent;
    border: none;
    height: 60%;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;

    &:focus {
        outline: 3px dotted var(--color-border-dice-roller);
        outline-offset: 5px;
    }

    &:hover {
        cursor: pointer;

        & .dice {
            width: 60%;
        }
    }

    & > .dice {
        transition: width 200ms linear;
        width: 50%;
        fill: black;
        stroke: white;
        stroke-width: 0.1px;
    }
}

.button--die-select {
    background-color: transparent;
    border: none;
    opacity: $low-emphasis;
    padding: 0;
    transition: transform 200ms linear;

    &:focus,
    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: 2px dotted var(--color-border-primary);
    }

    &:hover {
        transform: scale(1.1);
    }

    > .dice {
        fill: transparent;
        stroke: var(--color-border-primary);
        stroke-width: 0.5px;
        width: clamp(25px, 10vw, 50px);
    }
}

.active-die {
    opacity: 1;
}

.button--icon {
    --base-size: 1.5rem;

    height: var(--base-size);
    width: var(--base-size);
}

.button--outline {
    @extend .button;
    background-color: transparent;
    border: 1px solid var(--color-btn-outline);
    outline: 1px solid transparent;
    padding: 10px;

    &:hover,
    &:focus {
        border: 1px solid var(--color-btn-outline-focus);
        outline: 1px solid var(--color-btn-outline-focus);
    }

    &:focus {
        transform: scale(1.05);
    }
}

.button--text {
    @extend .button;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 0.5rem;
    width: max-content;

    &:hover,
    &:focus {
        border: 1px solid var(--color-border-btn-text);
    }
}

.button--nav {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-text-primary);

    &:hover,
    &:focus {
        background-color: var(--color-bg-header-item);
    }

    &:focus {
        border: 1px dotted var(--color-border-primary);
    }
}

.button--nav__icon {
    height: max(1.5rem, 40px);
    width: max(1.5rem, 40px);
}
