@use '../abstracts/' as *;

.about__main p:first-of-type {
  margin-top: 0;
}

.about__photo-container {
  min-height: 200px;
  min-width: 200px;
}

.about__photo {
  border-radius: 50%;
  height: auto;
  width: 100%;
}

.about__pronouns {
  margin-top: 10px;
}

.about__section {
  max-width: 800px;
}

.about__title {
  margin-bottom: 15px;
}
