@use '../abstracts/' as *;

.contact__list {
  list-style-type: none;
  padding-left: 0;
}

.contact__link {
  &:focus {
    outline: 2px dotted var(--color-border-primary);
    outline-offset: 5px;
  }

  &:hover {
    & .contact__icon {
      transform: scale(1.125);
      transition: transform 100ms linear;
    }
  }
}

.footer__nav .contact__icon {
  height: 25px;
}

.home-info .contact__container {
  margin-top: 50px;
}

.home-info .contact__icon {
  height: calc(25px + 1vw);
  max-height: 40px;
}
