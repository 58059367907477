@use '../abstracts/' as *;

.header-nav-container {
  background-color: var(--color-bg-modal);
  max-width: 250px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: right 200ms linear;
  width: 90%;
  z-index: 1;
}

.header-nav-mask {
  background-color: var(--color-bg-modal-mask);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 200ms linear;
  z-index: 1;
}

.hidden-mask {
  background-color: transparent;
  right: 100%;
}

.hidden-nav {
  right: -100%;
}

.header-nav-list__item {
  position: relative;
}
